.login{
    display: flex;
    justify-content: center; // 使子元素水平居中
    align-items:center; // 使子元素垂直居中
    width: 100%;
    height: 400px;

    .newcontainer{
        display: flex;
        justify-content: center; 
        align-items:center; 
        width: 60%;
        height:60%;
        -webkit-box-shadow:2px 4px 10px 1px rgba(0,0,0,0.47);
        box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);

        form{
            display: flex;
            flex-direction:column;

            .formInput{
                width: 90%;
                display: flex;
                flex-direction:row;
                margin-bottom: 20px;
                label{
                    width:150px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .icon{
                        cursor: pointer;
                    }
                }

                input{
                    width: 100%;
                    padding: 5px;
                    border: none;
                    border-bottom: 1px solid gray;
                }
            }

            .but{
                margin-top: 20px;
                width: 100%;
                display: flex;
                justify-content: space-between;
            }

            button{
                width: 120px;
                padding: 10px;
                border: none;
                background-color: teal;
                color: white;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
                
            }
        }
    }
}
