
:root {
    --main-color:#6c5ce7;
    --secondary-color: #a29bfe;
    --light-color: #b0b0c0;
    --lighter-color: #e0e0e7;
    --dark-color: #52505e;

    --font-smaller: 14px;
    --font-bigger: 20px;
}


*,::before,::after{
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}
ul{
    list-style: none;
}
h1,h2,h3{
    font-weight: 700;
    line-height: 1.25;
    margin: 0 0 0.5em;
}
img{
    max-width: 100%;
    vertical-align: middle;
}

.site{
    background-image: linear-gradient(135deg, #fd6585 10%, #0d25b9 100%);
    .container{
        max-width: 1100px;
        margin: 0 auto;
        padding: 2em;

        .form-box{
            height: auto;
            display: flex;
            flex-direction: column;
            border: 8px solid #e0e0e7;
            border-radius: 10px;
            background-color: #faf9ff;

            .progress{
                padding: 1em;
                position: relative;
                .logo{
                    font-size: 1.75em;
                    font-weight: 800;
                    color: #52505e;
                    padding: 40px 48px;
                    text-align: center;

                    span{
                        color: #6c5ce7;
                    }
                }

                .progress-steps{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 2em;

                    .step {
                        position:relative;
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 2em;


                        > span {
                            position: relative;
                            width: 40px;
                            height: 40px;
                            font-size: 14px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 1em;
                            border-radius: 50%;
                            background-color: #b0b0c0;
                            z-index: 1;
                        }


                        > p {
                            position: absolute;
                            top: -2em;
                            font-size: 12px;

                            > span {
                                font-size: 14px;
                                color: #b0b0c0;
                                display: none;
                            }

                        }
                    }
                    
                    .active{
                        > span{
                            color: white;
                            background-color: #6c5ce7;
                            border: 4px solid #a29bfe;
                            z-index: 1;
                        }
                    }
                }

            
            }

            .theform{
                padding: 2em;
                background-color: white;
                border-radius: 15px 15px 15px 15px;

                .form-one{
                    > p{
                        text-align: left;
                    }
                }
            }
        
        }
    }
}

form > div {
    max-width: 400px;
    margin: 0 auto;
}
form > div p{
    color: #b0b0c0;
    margin-bottom: 1em;
}
form > div > div:not(.checkbox){
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
}
form :where(input, select){
    height: 40px;
    padding: 0 1em;
    border-radius: 7px;
    outline-color: #a29bfe;
    border: 1px solid #b0b0c0;
    background-color: transparent;
}
form label{
    font-size: var(--font-smaller);
    margin-bottom: 0.5em;
    font-weight: 600;
}
form .grouping{
    display: flex;
}
form .grouping input{
    max-width: 70px;
    text-align: center;
    margin-right: 1em;
}

.react-tel-input .form-control{
    height: 40px !important;
    width: 100% !important;
    padding: 0 1em;
    border-radius: 7px;
    border: 4px solid #b0b0c0;
    background-color: transparent;
}


form .checkbox input{
    height: auto;
}

form > div:not(.btn-group){
    display: none;
    -webkit-animation: fadeIn .6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fadeIn .6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@keyframes fadeIn {
    0%{
        opacity: 0;
        -webkit-transform: translateY(10%);
        transform: translateY(10%);
    }
    100%{
        opacity: 1;
       -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
form > div.active{
    display: block;
}

form > div .bg-svg{
    width: 50px;
    height: 50px;
}
form > .form-one .bg-svg{
    background: url(imgs/personal.svg);
}
form > .form-two .bg-svg{
    background: url(imgs/contact.svg);
}
form > .form-three .bg-svg{
    background: url(imgs/identity.svg);
}

/* ==> Button */

.btn-group{
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 2em;
}
.btn-group [class*="btn-"]{
    background-color: #6c5ce7;
    color: white;
    padding: 1em 2em;
    border: 4px solid #a29bfe;
    border-radius: 30px;
    cursor: pointer;
    -webkit-transition: background-color .3s, border-color .3s ease-out;
    transition: background-color .3s, border-color .3s ease-out;
}
.btn-group [class*="btn-"]:disabled{
    background-color: #b0b0c0;
    border-color: #e0e0e7;
    cursor: text;
}
.btn-group [class*="btn-"]:hover:not(:disabled){
    background-color: #52505e;
    border-color: #b0b0c0;
}


.btn-group .btn-submit,
.form-three.active ~ .btn-group .btn-next{
    display: none;
}
.form-three.active ~ .btn-group .btn-submit{
    display: block;
}
.form-three.active ~ .-grbtnoup{
    justify-content:space-between;
}


::placeholder{
    color: #b0b0c0;
}


@media screen and (min-width: 768px){
    .site{
        .container{
            .form-box{
                flex-direction: row;
                .progress{
                    flex: 1;
                    min-width: 300px;
                    padding: 3em;

                    .progress-steps{
                        flex-direction: column;
                        gap: 0;
    
                        .step{
                            > p{
                                position: relative;
                                top: auto;
                                font-size: inherit;
    
                                > span{
                                    display: block;
                                }
                            }
                        }
                    }
                }

                .theform{
                    flex: 2;
                    padding: 3em;
                    background-color: white;
                    border-radius: 0px 15px 15px 0;
                }
            }
        }
    }

    .logo{
        margin: -48px -48px 48px;
    }

    .progress ul.progress-steps li:not(:last-child)::before{
        content: '';
        position: absolute;
        left: 20px;
        top: 30px;
        width: 2px;
        height: 65px;
        background-color: #e0e0e7;
    }



}

