
:root {
    --main-color:#6c5ce7;
    --secondary-color: #a29bfe;
    --light-color: #b0b0c0;
    --lighter-color: #e0e0e7;
    --dark-color: #52505e;

    --font-smaller: 14px;
    --font-bigger: 20px;
}

*,::before,::after{
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}
ul{
    list-style: none;
}
h1,h2,h3{
    font-weight: 700;
    line-height: 1.25;
    margin: 0 0 0.5em;
}
img{
    max-width: 100%;
    vertical-align: middle;
}
body{
    margin: 0;
    padding: 0;
}

.site{
    background-image: linear-gradient(135deg, #fd6585 10%, #0d25b9 100%);
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.container{
    max-width: 1100px;
    margin: 0 auto;
}


.form-box{
    height: 400px;
    display: flex;
    flex-direction: column;
    border: 8px solid var(--lighter-color);
    border-radius: 15px;
    background-color: #faf9ff;
}

.form-box h3{
    text-align: center;
    margin: 40px auto;
}

.form-box p{
    text-align: center;
}

.btn-group{
    margin: 40px auto;
}

.btn-group [class*="btn-"]{
    background-color: var(--main-color);
    color: white;
    padding: 1em 2em;
    border: 4px solid var(--secondary-color);
    border-radius: 30px;
    cursor: pointer;
    -webkit-transition: background-color .3s, border-color .3s ease-out;
    transition: background-color .3s, border-color .3s ease-out;
}

