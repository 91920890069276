.file-upload{
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
    background-color: hsl(235deg 100% 99%);
    box-shadow: hsl(235deg 100% 78% /30%) 0 25px 50px -12px;
}
.file-upload input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
}
.file-upload .icon{
    position: relative;
    margin-bottom: 5em;
}
.file-upload .icon ion-icon{
    font-size: 4em;
    color: hsla(235, 100%, 95%, 1);
    z-index: 1;
    position: relative;
}
.file-upload .icon::before, .file-upload .icon::after{
    content: '';
    width: 56px;
    height: 56px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    border: 3px solid hsla(235, 100%, 78%, 1);
    background-color: hsla(235, 100%, 99%, 1);
    transform: scale(1.5);
    z-index: 1;
}
.file-upload .icon::after{
    border: 0;
    transform: scale(2.5);
    z-index: 0;
    background-color: hsla(235, 100%, 95%, 1);
}
.file-upload h3{
    font-weight: 400;
    font-size: 1.5em;
    color: hsla(235, 100%, 78%, 1);
}
.file-upload button{
    border:0;
    outline: 0;
    border-radius: 50%;
    color: white;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-background: linear-gradient(135deg, hsla(235, 100%, 78%, 1) 0%, hsla(222, 100%, 67%, 1) 100%);
    background: linear-gradient(135deg, hsla(235, 100%, 78%, 1) 0%, hsla(222, 100%, 67%, 1) 100%);
    box-shadow: hsla(222, 100%, 67%, .5) 0 4px 18px;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}
.file-upload button ion-icon{
    font-size: 1.5em;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.file-upload:hover button{
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}
.list-upload{
    padding: 0 0em;
}
.list-upload ul li{
    position: relative;
    display: flex;
    margin: 3em 0;
    padding-right: 2em;
}
.list-upload .thumbnail{
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 20px;
    border-radius: 7px;
    background-color: hsla(235, 100%, 78%, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.list-upload .thumbnail > ion-icon{
    font-size: 2em;
    color: hsla(235, 100%, 99%, 1);
    display: none;
}
.file-list:where(.file, .application, .text) .thumbnail > [name="document-outline"],
.file-list.image .thumbnail > [name="image-outline"],
.file-list.image .thumbnail > [name="videocam-outline"],
.file-list.image .thumbnail > [name="musical-notes-outline"]{
    display: block;
}
.list-upload .thumbnail .completed{
    position: absolute;
    top: 50%;
    right: -10px;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #2ecc71;
    color: white;
    align-items: center;
    justify-content: center;
}
.list-upload .properties{
    height: 50px;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    gap: 5px;
}
.list-upload .properties .title{
    word-break: break-word;
    height: 12px;
}
.list-upload .properties .size{
    color: #8f98ff;
    font-size: 10px;
    height: 12px;
}


.list-upload .properties :where(.progress1, .buffer){
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    background-color: hsla(235, 100%, 95%, 1);
}
.list-upload .properties .buffer{
    width: 90%;
    -webkit-background: linear-gradient(90deg, #82f4b1 0%, #2ecc71 100%);
    background: linear-gradient(90deg, #82f4b1 0%, #2ecc71 100%);
}
.list-upload .properties .percentage{
    position: absolute;
    left: 0;
    top: 5px;
    font-size: 10px;
}
.list-upload .remove{
    position: absolute;
    right: 0;
    top: 50%;
    border: 0;
    outline: 0;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    border-radius: 50%;
    background-color: #ff6b81;
    color: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}
.list-upload .remove:hover{
    background-color: #303030;
}
.list-upload :where(.completed, .remove){
    display: none;
}


.uploadlabel{
    width: 100%;
    min-height: 100px;
    background: #18a7ff0d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #18a7ff82;
    cursor: pointer;
}
.uploadlabel span{
    font-size: 70px;
    color: #18a7ff;
}
.uploadlabel p{
    color: #18a7ff;
    font-size: 20px;
    font-weight: 800;
    font-family: cursive;
}