.userform{
    width: 100%;
    display: flex;

    .newContainer{
        flex: 6;
        .top,
        .bottom
        {
            -webkit-box-shadow:2px 4px 10px 1px rgba(0,0,0,0.47);
            box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);
            padding: 20px;
            margin: 20px;
            display: flex;

            h1{
                color: lightgray;
                font-size: 20px;
            }

            .left{
                flex:1;
                text-align: center;

                img{
                    width: 80%;
                    border-radius: 5px;
                    object-fit: cover;
                }
            }

            .right{
                flex:2;
                
                form{
                    display: flex;
                    flex-wrap: wrap;
                    gap:20px;
                    justify-content: space-around;

                    .textInput{
                        width: 100%;
                        display: flex;

                        label{
                            display: flex;
                            align-items: center;
                            width: 100px;
                            gap: 10px;

                            .icon{
                                cursor: pointer;
                            }
                        }

                        input{
                            width: 100%;
                            padding: 5px;
                            border: none;
                            border-bottom: 1px solid gray;
                        }
                    }

                    .but{
                        width: 100%;
                        display: flex;
                        justify-content: space-evenly;
                    }

                    button{
                        width: 120px;
                        padding: 10px;
                        border: none;
                        background-color: teal;
                        color: white;
                        font-weight: bold;
                        cursor: pointer;
                        margin-top: 10px;
                        
                    }
                }
            }
        }
    }
}