.orderform{
    width: 100%;
    display: flex;

    .newContainer{
        flex: 6;

        .top,
        .bottom
        {
            -webkit-box-shadow:2px 4px 10px 1px rgba(0,0,0,0.47);
            box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);
            padding: 20px;
            margin: 20px;
            display: flex;

            h1{
                color: lightgray;
                font-size: 20px;
            }

                
            form{
                display: flex;
                flex-wrap: wrap;
                gap:20px;
                justify-content: space-around;

                label{
                    width:150px;
                }

                .formInput{
                    width: 40%;
                    display: flex;
                    label{
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .icon{
                            cursor: pointer;
                        }
                    }
                    input{
                        width: 96%;
                        padding: 5px;
                        border-bottom: 1px solid gray;
                    }
                }

            

                .but{
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;
                }

                button{
                    width: 120px;
                    padding: 10px;
                    border: none;
                    background-color: teal;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;
                    margin-top: 10px;
                    
                }
            }
            
        }
    }
}