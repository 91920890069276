.datatable{
    height: 600px;
    padding: 20px;

    .datatableTitle{
        width: 100%;
        font-size: 24px;
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .link{
            text-decoration: none;
            color: green;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid green;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .cellWithImg{
        display: flex;
        align-items: center;

        .cellImg{
            width:32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 10px;
        }


    }

    .cellWithStatus{
        padding: 5px;
        border-radius: 5px;

        .approved{
            background-color: rgb(65, 59, 59);
            color: green;
        }

        .pending{
            background-color: rgb(29, 154, 81);
            color: rgb(161, 230, 161);
        }
    }

    .cellAction{
        display: flex;
        align-items: center;
        gap: 15px;

        .viewButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: darkblue;
            border: 1px dotted rgb(0, 0, 139, 0.596);
            cursor: pointer;
            

            .viewLink{
                text-decoration: none;
            }


        }
        
        .deleteButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: crimson;
            border: 1px dotted rgba(61, 58, 59, 0.658);
            cursor: pointer;

            .deleteLink{
                text-decoration: none;
            }
        }
    }
}