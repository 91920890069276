
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;800&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');


body{
  font-family: 'Nunito',sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  color: var(--dark-color);
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

html{
  scroll-behavior: smooth;
}

